.App {
  text-align: center;
  background-image: url('./images/cyoa-cover.png'); /* Adjust path as needed */
  background-size: cover; /* Cover the entire screen */
  background-position: center; /* Center the background image */
  min-height: 10vh; /* Ensure it covers the whole viewport */
}

.App-logo {
  height: 2vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(5px + 1vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.door-button {
  background-color: #53d99d; /* Brown color for a wooden door effect */
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); /* Adding some shadow for depth */
  transition-duration: 0.4s; /* Smooth transition for hover effect */
}

.door-button:hover {
  background-color: #654321; /* Darker shade when hovering */
  color: #f0e68c; /* Lighter text color when hovering */
}

.story-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

}

.story-card {
  background-color: #e5c8ec;
  border-radius: 3px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin: 40px;
  padding: 20px;
  text-align: center;
  min-width: 250px; /* Adjust the width as needed */
  transition: transform 0.2s; /* Animation for hover effect */
}

.story-card:hover {
  transform: scale(1.05); /* Slightly enlarges the card on hover */
}

.story-card h3 {
  margin-top: 0;
}

.story-card p {
  color: #555;
}

.story-item {
  background-color: #e5c8ec;
  border-radius: 3px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin: 40px;
  padding: 40px;
  text-align: center;
  width: 300px; /* Adjust the width as needed */
  transition: transform 0.2s; /* Animation for hover effect */
}

.decision-button {
  background-color: #9e4ea3; /* Brown color for a wooden door effect */
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 30px 10px;
  cursor: pointer;
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); /* Adding some shadow for depth */
  transition-duration: 0.4s; /* Smooth transition for hover effect */
}

.decision-button:hover {
  background-color: #602165; /* Darker shade when hovering */
  color: #f0e68c; /* Lighter text color when hovering */
}

.back-to-story-button {
  background-color: #e69983; /* Brown color for a wooden door effect */
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); /* Adding some shadow for depth */
  transition-duration: 0.4s; /* Smooth transition for hover effect */
}

.back-to-story-button:hover {
  background-color: #b54b2d; /* Darker shade when hovering */
  color: #f0e68c; /* Lighter text color when hovering */
}

